// ResultsPage.js

import React from "react";
import { useLocation } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Results from "./Results";
import LockedUserInputForm from "./LockedUserInputForm";

const ResultsPage = () => {
  let { state } = useLocation();
  return (
    <div className="App">
      <Tabs defaultActiveKey="Brand" id="fill-tab-example" fill>
        <Tab eventKey="Brand" title="Generated Brand">
          <div className="right-content">
            <Results jsonResults={state} />
          </div>
        </Tab>
        <Tab eventKey="BrandRequest" title="Brand Request">
          <div className="left-content">
            <LockedUserInputForm
              locked={true}
              description={state.projectDescription}
              theming={state.theming}
            />
          </div>
        </Tab>
      </Tabs>
    </div>
  );
};

export default ResultsPage;
