/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_appsync_graphqlEndpoint": "https://fjnm2jmc4jhoncw2vlzhqvpif4.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-unqbjsfjpne6jojuzju73pxe2i",
    "aws_cloud_logic_custom": [
        {
            "name": "restapi",
            "endpoint": "https://2d3assjdv4.execute-api.us-east-2.amazonaws.com/stage",
            "region": "us-east-2"
        },
        {
            "name": "stripeapi",
            "endpoint": "https://dzj425c1qe.execute-api.us-east-2.amazonaws.com/stage",
            "region": "us-east-2"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-2:1b02dcd9-e533-42e2-b003-86d22baa9ed0",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_J7EcMAhR8",
    "aws_user_pools_web_client_id": "5ibl4bhl51vogdk5b587c85i3r",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "us-east-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "dynamoUserResults-stage",
            "region": "us-east-2"
        }
    ],
    "aws_user_files_s3_bucket": "logobucket190403-stage",
    "aws_user_files_s3_bucket_region": "us-east-2"
};


export default awsmobile;
