import React, { useState } from "react";
import { BsDownload } from "react-icons/bs";
import "../styles/buttons.css";

const ImageWithDownloadButton = ({ src, alt }) => {
  const [showDownload, setShowDownload] = useState(true);

  return (
    <div
      style={{
        position: "relative",
        display: "inline-block",
        margin: "10px",
        textAlign: "center",
      }}
    >
      <img
        src={src}
        alt={alt}
        className="carousal-image"
        style={{ maxWidth: "100%", height: "auto" }}
      />
      {showDownload && (
        <div
          style={{
            position: "absolute",
            top: "3%",
            left: "5%",
          }}
          className="download-button-wrapper"
        >
          <a href={src} download={alt}>
            <BsDownload className="download-button" />
          </a>
        </div>
      )}
    </div>
  );
};

export default ImageWithDownloadButton;
