import Amplify, { Auth, API } from "aws-amplify";
import { getUser } from "../graphql/queries";

export async function getCurrentUser() {
  try {
    const user = await Auth.currentAuthenticatedUser();
    return user;
  } catch (error) {
    console.error("Error getting current user:", error);
    return null;
  }
}

export async function getCurrentUserCredits() {
  try {
    const user = await Auth.currentAuthenticatedUser();

    const response = await API.graphql({
      query: getUser,
      variables: { email: user.attributes.email },
    });

    return response.data.getUser.credits;
  } catch (err) {
    console.log("Error getting user credits: ", err);
  }
}
