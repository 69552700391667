import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Carousel } from "react-responsive-carousel";
import { getImageURL } from "../helpers/getImage";
import ImageWithDownloadButton from "./ImageWithDownloadButton";
import "../styles/results.css";
import "../styles/images.css";
import "../styles/lists.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const Results = ({ jsonResults }) => {
  const { names, slogans, logos } = jsonResults;

  const [images, setImages] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const uniqueImages = new Set();

        for (const logo of logos) {
          const url = await getImageURL(logo);
          uniqueImages.add(url);
        }

        setImages([...uniqueImages]);
      } catch (err) {
        console.log("Error loading logo images");
      }
    }

    fetchData();
  }, []);

  return (
    <Container className="results-container">
      <Row>
        {/* Names Column */}
        <Col md={6}>
          <div className="list-container">
            <h5>Names:</h5>
            <ul>
              {names.map((name, index) => (
                <li key={index}>{name}</li>
              ))}
            </ul>
          </div>
        </Col>

        {/* Slogans Column */}
        <Col md={6}>
          <div className="list-container">
            <h5>Slogans:</h5>
            <ul>
              {slogans.map((slogan, index) => (
                <li key={index}>{slogan}</li>
              ))}
            </ul>
          </div>
        </Col>
      </Row>

      {/* Logos Carousel */}
      <Row>
        <Col className="carousal-wrapper">
          <h5>Logos:</h5>
          <Carousel
            swipeable={true}
            showArrows={true}
            infiniteLoop={true}
            showThumbs={false}
            className="carousal-container"
          >
            {images.map((logo, index) => (
              <ImageWithDownloadButton
                key={index}
                src={logo}
                alt={"logo: " + index}
              />
            ))}
          </Carousel>
        </Col>
      </Row>
    </Container>
  );
};

export default Results;
