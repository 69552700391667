import { Navbar, Container, Nav, Button } from "react-bootstrap";
import BuyCreditsModal from "./BuyCreditsModal";
import { Auth } from "aws-amplify";

import React, { useContext, useState, useEffect } from "react";
import { AppContext } from "../App";

import { getCurrentUser, getCurrentUserCredits } from "../helpers/getUser";

import "../styles/buttons.css";

function MyNavbar() {
  const [showModal, setShowModal] = useState(false);
  const { userEmail, setUserEmail, userCredits, setUserCredits } =
    useContext(AppContext);

  useEffect(() => {
    async function fetchUser() {
      try {
        const user = await getCurrentUser();
        const email = user.attributes.email;
        setUserEmail(email);

        const creds = await getCurrentUserCredits();
        setUserCredits(creds);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchUser(); // Invoke the async function
  }, [setUserEmail, setUserCredits]);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleSignOut = async () => {
    try {
      await Auth.signOut(); // Sign out the current user
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  return (
    <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary">
      <Container>
        <img
          alt=""
          src="/images/BrandWizLogo.png"
          width="30"
          height="30"
          className="d-inline-block align-top"
        />

        <Navbar.Brand href="/">BrandWiz</Navbar.Brand>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          className="collapse-button"
        />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto"></Nav>

          <Nav>
            <Button
              className="nav-button"
              variant="primary"
              onClick={handleOpenModal}
            >
              Add Credits
            </Button>
          </Nav>
          <div className="user-info-container">
            <div>
              <div>{userEmail}</div>
              Credits: {userCredits}
            </div>
            <Button
              className="sign-out-button"
              variant="danger"
              onClick={handleSignOut}
            >
              Sign Out
            </Button>
          </div>
        </Navbar.Collapse>
      </Container>
      <BuyCreditsModal
        showModal={showModal}
        handleCloseModal={handleCloseModal}
      />
    </Navbar>
  );
}

export default MyNavbar;
