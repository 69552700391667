import React, { useState, useEffect } from "react";
import "../styles/form.css";
import Switch from "react-switch";

import LockedColorPicker from "./LockedColorPicker";
import CheckboxWithImage from "./CheckboxWithImage";

function LockedUserInputForm(props) {
  function doNothing(e) {
    e.preventDefault();
  }

  return (
    <div className="user-input-form">
      <form>
        <div className="form-group">
          <label className="section-header">Project Description</label>
          <textarea
            readOnly={true}
            className="project-description"
            value={props.description}
            maxLength={600}
          />
          <div className="character-counter">
            {props.description.length}/600
          </div>
        </div>

        <div className="divider"></div>
        <div className="section-header">Theming</div>

        <div className="theme-group disabled-theme-group">
          <div className="switch-group">
            {/* Switch for Modern/Classic */}
            <div className="form-switch">
              <div className="switch-label">Classic</div>
              <Switch
                onChange={doNothing}
                checked={props.theming.modern}
                onColor="#9e5aed"
                offColor="#666"
              />
              <div className="switch-label">Modern</div>
            </div>

            {/* Switch for Black and White/Colorful */}
            <div className="form-switch">
              <div className="switch-label">Black and White</div>
              <Switch
                onChange={doNothing}
                checked={props.theming.colorful}
                onColor="#9e5aed"
                offColor="#666"
              />
              <div className="switch-label">Colorful</div>
            </div>

            {/* Switch for Flat/3D */}
            <div className="form-switch">
              <div className="switch-label">Flat</div>
              <Switch
                onChange={doNothing}
                checked={props.theming.threeD}
                onColor="#9e5aed"
                offColor="#666"
              />
              <div className="switch-label">3D</div>
            </div>

            <div>
              <label>Logo Type</label>
              <div className="checkbox-container">
                <CheckboxWithImage
                  image="images/LettermarkLogo.png"
                  label="Lettermark"
                  isChecked={props.theming.logoType === "Lettermark"}
                  handleCheckboxChange={doNothing}
                />
                <CheckboxWithImage
                  image="images/MascotLogo.png"
                  label="Mascot"
                  isChecked={props.theming.logoType === "Mascot"}
                  handleCheckboxChange={doNothing}
                />
                <CheckboxWithImage
                  image="images/EmblemLogo.png"
                  label="Emblem"
                  isChecked={props.theming.logoType === "Emblem"}
                  handleCheckboxChange={doNothing}
                />
              </div>
            </div>
          </div>

          <div
            className={`color-picker-group ${
              props.theming.colorful ? "" : "disabled-color-picker"
            }`}
          >
            <LockedColorPicker colors={props.theming.colors} />
          </div>
        </div>

        <div className="divider"></div>
      </form>
    </div>
  );
}

export default LockedUserInputForm;
