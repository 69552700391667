import React, { useEffect, useState } from "react";
import "../styles/form.css";
import "../styles/colorPicker.css";
import { ChromePicker } from "react-color";

function LockedColorPicker(selectedColors) {
  const renderFakeBoxes = () => {
    const fakeBoxElements = [];

    for (let i = 0; i < 6; i++) {
      fakeBoxElements.push(
        <div key={i + 7} className="color-box">
          <div
            className="fake-box-inner"
            style={{ backgroundColor: "transparent" }}
          ></div>
        </div>
      );
    }

    return fakeBoxElements;
  };

  return (
    <div className="color-picker">
      <ChromePicker />

      <div className="picker-buttons-and-boxes">
        <div className="selected-colors">
          <h4>Selected Colors:</h4>
          <div className="overlay-wrapper">
            <div className="color-rows">
              {selectedColors.colors.map((color, index) => (
                <div key={index} className="color-box ">
                  <div
                    className="color-box-inner"
                    style={{ backgroundColor: color }}
                  ></div>
                </div>
              ))}
            </div>
          </div>
          <div className="overlay-wrapper">
            <div className="color-rows overlay">{renderFakeBoxes()}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LockedColorPicker;
