import React, { useState } from "react";
import "../styles/checkbox.css";

const CheckboxWithImage = ({
  image,
  label,
  isChecked,
  handleCheckboxChange,
}) => {
  return (
    <div className="checkbox-wrapper-16">
      <label
        className={`checkbox-tile ${isChecked ? "checked" : ""}`}
        onClick={handleCheckboxChange}
      >
        <span className="checkbox-icon">
          <img src={image} alt="Checkbox Icon" />
        </span>
        <span className="checkbox-label">{label}</span>
      </label>
    </div>
  );
};

export default CheckboxWithImage;
