import { API } from "aws-amplify";
import { getCurrentUser } from "../helpers/getUser";

import "../styles/product.css";
import "../styles/buttons.css";

const ProductDisplay = (numberCredits) => {
  const sendCheckoutRequest = async () => {
    try {
      const user = await getCurrentUser();
      const response = await API.post("stripeapi", "/checkout1", {
        body: {
          userEmail: user.attributes.email,
          numberCredits: numberCredits.numberCredits,
        },
      });
      const stripeURL = response.stripeURL;
      window.location.href = stripeURL;
    } catch (err) {
      console.log("Error sending Stripe request: ", err);
    }
  };

  const handleCheckoutClick = () => {
    sendCheckoutRequest();
  };

  return (
    <section>
      <div className="product">
        <div className="description">
          <h5>
            {numberCredits.numberCredits === 1
              ? "1 BrandWiz Credit"
              : "5 BrandWiz Credits"}{" "}
          </h5>
          <div>{numberCredits.numberCredits === 1 ? "0.99" : "2.99"}</div>
        </div>

        <button
          className="buy-button"
          type="button"
          onClick={handleCheckoutClick}
        >
          Checkout
        </button>
      </div>
    </section>
  );
};

export default ProductDisplay;
