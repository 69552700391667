import { Storage } from "aws-amplify";

export async function getImageURL(imageKey) {
  try {
    const key = imageKey.replace("public/", "");
    const imageURL = await Storage.get(key);
    return imageURL;
  } catch (error) {
    console.error("Error fetching image:", error);
  }
}
