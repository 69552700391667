import React from "react";
import ReactLoading from "react-loading";
import "../styles/loadingPage.css";

const LoadingPage = ({
  onBackClick,
  onViewResultsClick,
  doneGenerating,
  successfulResults,
}) => {
  return (
    <div className="color-primary-1 loading-page ">
      {doneGenerating ? (
        successfulResults ? (
          <h3>Your Brand is Ready!</h3>
        ) : (
          <h3>An Error has Ocurred</h3>
        )
      ) : (
        <h3 className="color-primary-3">Building your brand!</h3>
      )}

      {!doneGenerating && <p>It may take around 30 seconds to complete.</p>}
      {doneGenerating ? (
        successfulResults ? (
          <p>Done Generating Brand!</p>
        ) : (
          <p>Generation failed. Please try again later.</p>
        )
      ) : (
        <ReactLoading
          type="balls"
          color="#C89CF6"
          height={"20%"}
          width={"20%"}
        />
      )}

      {!doneGenerating ? (
        <p>You can leave this page, and the generation will continue.</p>
      ) : (
        <></>
      )}
      <div className="loading-page-buttons-container">
        <button
          onClick={onBackClick}
          className="loading-page-button loading-page-back"
        >
          Back
        </button>

        <button
          onClick={onViewResultsClick}
          className="loading-page-button loading-page-view-results "
          disabled={!doneGenerating || !successfulResults}
        >
          View Results
        </button>
      </div>
    </div>
  );
};

export default LoadingPage;
