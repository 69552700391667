/* eslint-disable */

export const customListUserResults = /* GraphQL */ `
  query ListUserResults(
    $id: String
    $filter: ModelUserResultsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUserResults(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        email
        objs
        status
        __typename
      }
      nextToken
      __typename
    }
  }
`;
