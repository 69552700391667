import React from "react";
import { Modal, Button } from "react-bootstrap";
import "../styles/buttons.css";

const NoCreditsModal = ({ show, handleClose, handleBuyCredits }) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Out of Credits!</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Oops! It looks like you've run out of credits to create another brand.
        </p>
        <p>
          Purchase more credits to continue unleashing the power of Brand Wiz!
        </p>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="submit-button"
          type="button"
          onClick={handleBuyCredits}
        >
          Buy Credits
        </button>
        {/* <Button variant="primary" onClick={handleBuyCredits}>
          Buy Credits
        </Button> */}
      </Modal.Footer>
    </Modal>
  );
};

export default NoCreditsModal;
