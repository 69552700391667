import React, { useContext, useEffect, useState } from "react";
import { FormContext } from "./HomePage";
import "../styles/form.css";
import "../styles/colorPicker.css";
import "../styles/buttons.css";
import { ChromePicker } from "react-color";

function ColorPicker() {
  const { colors, setColors } = useContext(FormContext);
  const [currentColor, setCurrentColor] = useState("#000000");
  const [isFull, setIsFull] = useState(false);

  const handleColorChange = (event) => {
    const newColor = event.hex;
    setCurrentColor(newColor);
  };

  const handleAddColor = (e) => {
    e.preventDefault();

    if (colors.length < 6) {
      if (colors.length === 5) {
        setIsFull(true);
      }
      setColors([...colors, currentColor]);
    }
  };

  const handleRemoveColor = (e) => {
    e.preventDefault();
    setIsFull(false);
    if (colors.length > 0) {
      const updatedColors = [...colors];
      updatedColors.pop(); // Remove the last color
      setColors(updatedColors);
    }
  };

  const renderFakeBoxes = () => {
    const fakeBoxElements = [];

    for (let i = 0; i < 6; i++) {
      fakeBoxElements.push(
        <div key={i + 7} className="color-box">
          <div
            className="fake-box-inner"
            style={{ backgroundColor: "transparent" }}
          ></div>
        </div>
      );
    }

    return fakeBoxElements;
  };

  return (
    <div className="color-picker">
      <ChromePicker color={currentColor} onChange={handleColorChange} />

      <div className="picker-buttons-and-boxes">
        <div className="picker-buttons">
          <button
            onClick={handleAddColor}
            disabled={isFull}
            className="picker-button"
          >
            Add Color
          </button>
          <button
            className="picker-button remove-button"
            onClick={handleRemoveColor}
          >
            Remove Color
          </button>
        </div>
        <div className="selected-colors">
          <h4>Selected Colors:</h4>
          <div className="overlay-wrapper">
            <div className="color-rows">
              {colors.map((color, index) => (
                <div key={index} className="color-box ">
                  <div
                    className="color-box-inner"
                    style={{ backgroundColor: color }}
                  ></div>
                </div>
              ))}
            </div>
          </div>
          <div className="overlay-wrapper">
            <div className="color-rows overlay">{renderFakeBoxes()}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ColorPicker;
