import React, { useState, useEffect } from "react";
import { Storage } from "aws-amplify";
import "../styles/requestCard.css";
import { Link } from "react-router-dom";

const RequestCard = ({ jsonData }) => {
  const [data, setData] = useState(null);
  const [imageURL, setImageURL] = useState(null);
  const [dateString, setDateString] = useState(null);

  useEffect(() => {
    try {
      const parsedData = JSON.parse(jsonData);
      parsedData["logos"] = parsedData["logos"].map((str) =>
        str.replace("public/", "")
      );
      setData(parsedData);
      getDateFromImageKey(parsedData.logos[0]);
      fetchImage(parsedData.logos[0]);
    } catch (error) {
      console.error("Error parsing JSON:", error);
    }
  }, [jsonData]);

  const getDateFromImageKey = (key) => {
    // Split the image key by hyphen to extract the timestamp
    const parts = key.split("-");
    if (parts.length === 2) {
      const timestamp = parseInt(parts[0], 10);
      const date = new Date(timestamp * 1000); // Convert the timestamp to a date
      setDateString(date.toDateString()); // Set the date as a state variable
    }
  };

  const fetchImage = async (imageKey) => {
    try {
      const imageURL = await Storage.get(imageKey);
      setImageURL(imageURL);
    } catch (error) {
      console.error("Error fetching image:", error);
    }
  };

  if (!data) {
    return null; // If data is not parsed yet, return nothing
  }

  return (
    <Link to="/results" state={data} style={{ textDecoration: "none" }}>
      <div className="request-card">
        <div className="text-container">
          <h3>{data.names[0]}</h3>
          <h4>{dateString}</h4>
        </div>

        {imageURL && (
          <img src={imageURL} alt={data.Name1} className="logo-image" />
        )}
      </div>
    </Link>
  );
};

export default RequestCard;
