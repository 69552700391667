import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";

import ProductDisplay from "./ProductDisplay";

function BuyCreditsModal({ showModal, handleCloseModal }) {
  return (
    <Modal show={showModal} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <div>
          <Modal.Title>Buy Credits</Modal.Title>
          <div>*1 credit per brand generation</div>
        </div>
      </Modal.Header>
      <Modal.Body>
        {/* Add the content of your modal here */}
        {/* For example, you can display a form for buying credits */}
        <ProductDisplay numberCredits={1} />
        <ProductDisplay numberCredits={5} />
      </Modal.Body>
    </Modal>
  );
}

export default BuyCreditsModal;
