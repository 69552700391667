// HomePage.js

import React, { useState, createContext, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { API } from "aws-amplify";

import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import UserInputForm from "./UserInputForm";
import UserRequestList from "./UserRequestList";
import LoadingPage from "./LoadingPage";
import PaymentFailedModal from "./PaymentFailedModal";
import PaymentSuccessfulModal from "./PaymentSuccessfulModal";
import NoCreditsModal from "./NoCreditsModal";
import BuyCreditsModal from "./BuyCreditsModal";
import { getCurrentUser, getCurrentUserCredits } from "../helpers/getUser";
import { AppContext } from "../App";
import "../App.css";
import "../styles/tabs.css";

export const FormContext = createContext();
export const ResultListContext = createContext();

const HomePage = () => {
  const [currentResults, setCurrentResults] = useState();
  const [showLoadingPage, setShowLoadingPage] = useState(false);
  const [generatingResults, setGeneratingResults] = useState(true);
  const [successfulResult, setSuccessfulResult] = useState(false);

  const [description, setDescription] = useState("");
  const [isModern, setIsModern] = useState(true);
  const [isColorful, setIsColorful] = useState(true);
  const [is3D, setIs3D] = useState(true);
  const [logoType, setLogoType] = useState("Lettermark");
  const [colors, setColors] = useState([]);

  const [resultList, setResultList] = useState([]);

  // state vars for payment confirmation modals
  const [success, setSuccess] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showFailureModal, setShowFailureModal] = useState(false);
  const [showNoCreditsModal, setShowNoCreditsModal] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);

  const { userCredits, setUserCredits } = useContext(AppContext);

  let intervalID;

  const navigate = useNavigate();

  useEffect(() => {
    // Function to parse URL parameters
    const getURLParameters = () => {
      const urlParams = new URLSearchParams(window.location.search);
      const successParam = urlParams.get("success");
      if (successParam !== null) {
        if (successParam === "true") {
          setShowSuccessModal(true);
        } else if (successParam === "false") {
          setShowFailureModal(true);
        }
      }
    };

    getURLParameters();
  }, []);

  const handleCloseSuccessModal = () => setShowSuccessModal(false);
  const handleCloseFailureModal = () => setShowFailureModal(false);
  const handleCloseNoCreditModal = () => setShowNoCreditsModal(false);
  const handleBuyCredits = () => {
    setShowNoCreditsModal(false);
    setShowPaymentModal(true);
  };
  const handleClosePaymentModal = () => setShowPaymentModal(false);

  const checkForData = async (email, id) => {
    try {
      // Define your query parameters here
      const queryParams = {
        email: email,
        id: id,
      };

      const response = await API.get("restapi", "/check-status", {
        queryStringParameters: queryParams,
      });

      const resultsStatus = response["status"]["S"];
      console.log(response);
      if (resultsStatus === "successful") {
        const formattedResponse = JSON.parse(response.objs.S);
        const resultsListObj = {
          id: response.id.S,
          objs: response.objs.S,
        };
        setCurrentResults(formattedResponse);
        updateResultsList(resultsListObj);
        setGeneratingResults(false);
        setSuccessfulResult(true);
        const creds = await getCurrentUserCredits();
        setUserCredits(creds);

        clearInterval(intervalID);
        intervalID = null;
      } else if (resultsStatus === "failed") {
        setGeneratingResults(false);
        clearInterval(intervalID);
        intervalID = null;
      } else if (resultsStatus === "in-progress") {
      } else if (resultsStatus === "failed no credits") {
        setGeneratingResults(false);
        clearInterval(intervalID);
        intervalID = null;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setGeneratingResults(false);
      clearInterval(intervalID);
      intervalID = null;
    }
  };

  const handleSubmit = async () => {
    try {
      const currentUserCredits = await getCurrentUserCredits();

      if (currentUserCredits <= 0) {
        setShowNoCreditsModal(true);
      } else {
        setShowLoadingPage(true);
        setGeneratingResults(true);
        const user = await getCurrentUser();

        if (user) {
          // fetch from generate endpoint
          const response = await API.post("restapi", "/generate-results", {
            body: {
              userEmail: user.attributes.email,
              username: user.username,
              productDescription: description,
              isModern: isModern,
              isColorful: isColorful,
              is3D: is3D,
              logoType: logoType,
              colors: colors,
            },
          });

          intervalID = setInterval(
            checkForData,
            20000,
            user.attributes.email,
            response["requestID"]
          );

          // Clear intervalID if it hasn't been cleared in 1 minute
          setTimeout(() => {
            if (intervalID) {
              clearInterval(intervalID);
              setGeneratingResults(false);
              intervalID = null;
              console.log("Automatically cleared interval after 1 minute.");
            }
          }, 60000);
        }
      }
    } catch (err) {
      console.log("error fetching endpoint: " + err);
    }
  };

  const updateResultsList = (newResult) => {
    setResultList((prev) => [...prev, newResult]);
    console.log("New Results List: ", [...resultList, newResult]);
  };

  const handleLoadingBack = () => {
    setShowLoadingPage(false);
  };

  const handleShowNewResults = () => {
    // redirect to results page with currentResults
    navigate("/results", { state: currentResults });
  };

  return (
    <FormContext.Provider
      value={{
        description,
        setDescription,
        isModern,
        setIsModern,
        isColorful,
        setIsColorful,
        is3D,
        setIs3D,
        logoType,
        setLogoType,
        colors,
        setColors,
      }}
    >
      <ResultListContext.Provider
        value={{
          resultList,
          setResultList,
        }}
      >
        <div className="App">
          <Tabs defaultActiveKey="BrandRequest" id="fill-tab-example" fill>
            <Tab eventKey="BrandRequest" title="Build a Brand">
              <div className="left-content">
                {showLoadingPage ? (
                  <LoadingPage
                    onBackClick={handleLoadingBack}
                    onViewResultsClick={handleShowNewResults}
                    doneGenerating={!generatingResults}
                    successfulResults={successfulResult}
                  />
                ) : (
                  <>
                    <UserInputForm onSubmitFunction={handleSubmit} />
                  </>
                )}
              </div>
            </Tab>
            <Tab eventKey="PreviousBrands" title="Previous Brands">
              <div className="right-content">
                <UserRequestList key={1} />
              </div>
            </Tab>
          </Tabs>
          {/* successful payment Modal */}
          <PaymentSuccessfulModal
            show={showSuccessModal}
            handleClose={handleCloseSuccessModal}
          />
          {/* failed payment Modal */}
          <PaymentFailedModal
            show={showFailureModal}
            handleClose={handleCloseFailureModal}
          />
          {/* No Credits Modal */}
          <NoCreditsModal
            show={showNoCreditsModal}
            handleClose={handleCloseNoCreditModal}
            handleBuyCredits={handleBuyCredits}
          />
          {/* Buy Credits Modal */}
          <BuyCreditsModal
            showModal={showPaymentModal}
            handleCloseModal={handleClosePaymentModal}
          />
        </div>
      </ResultListContext.Provider>
    </FormContext.Provider>
  );
};

export default HomePage;
