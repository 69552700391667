import { Heading, Flex, Image, useTheme } from "@aws-amplify/ui-react";

export function Header() {
  const { tokens } = useTheme();

  return (
    <Flex
      justifyContent="center"
      direction="column"
      alignItems="center"
      style={{ paddingBottom: "15px" }}
    >
      <Image
        alt="logo"
        src="images/BrandWizLogo.png"
        padding={tokens.space.small}
        style={{
          width: "60%",
        }}
      />
      <Heading level={4}>Welcome to BrandWiz</Heading>
      <Heading level={5}>Login or Sign up to build your brand!</Heading>
    </Flex>
  );
}
