import React, { useContext, useEffect, useState } from "react";
import { API, Auth } from "aws-amplify";

import { ResultListContext } from "./HomePage";
import "../styles/requestCard.css";
import RequestCard from "./RequestCard";
import { customListUserResults } from "../my-graphql/myQueries";

// Custom sorting function
function sortByDate(a, b) {
  const dateA = new Number(a.id.split("-")[0]);
  const dateB = new Number(b.id.split("-")[0]);

  // Compare the dates in descending order (most recent first)
  if (dateA > dateB) return -1;
  if (dateA < dateB) return 1;
  return 0;
}

function UserRequestList() {
  const { resultList, setResultList } = useContext(ResultListContext);
  const [userEmail, setUserEmail] = useState();

  useEffect(() => {
    async function fetchData() {
      try {
        const user = await Auth.currentAuthenticatedUser();
        const email = user.attributes.email;
        setUserEmail(email);
        const response = await API.graphql({
          query: customListUserResults,
          variables: {
            filter: {
              email: { eq: email },
              status: { eq: "successful" },
            },
          },
          authMode: "AMAZON_COGNITO_USER_POOLS",
        });
        const items = response.data.listUserResults.items;
        items.sort(sortByDate);
        setResultList(items);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, []);

  return (
    <div className="previous-requests-panel">
      {resultList.length === 0 ? (
        <div className="no-results-block">No brands generated yet!</div>
      ) : (
        <div className="request-card-container">
          {resultList.map((result) => (
            <RequestCard jsonData={result.objs} key={result.id} />
          ))}
        </div>
      )}
    </div>
  );
}

export default UserRequestList;
