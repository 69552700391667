/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($email: String!) {
    getUser(email: $email) {
      email
      credits
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $email: String
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUsers(
      email: $email
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        email
        credits
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserResults = /* GraphQL */ `
  query GetUserResults($id: String!) {
    getUserResults(id: $id) {
      id
      email
      objs
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUserResults = /* GraphQL */ `
  query ListUserResults(
    $id: String
    $filter: ModelUserResultsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUserResults(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        email
        objs
        status
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const userResultsByEmailAndId = /* GraphQL */ `
  query UserResultsByEmailAndId(
    $email: String!
    $id: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserResultsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userResultsByEmailAndId(
      email: $email
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        objs
        status
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
