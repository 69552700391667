import React, { useContext, useState } from "react";
import { FormContext } from "./HomePage";
import "../styles/form.css";
import "../styles/buttons.css";
import Switch from "react-switch";

import ColorPicker from "./ColorPicker";
import CheckboxWithImage from "./CheckboxWithImage";

function UserInputForm(props) {
  const {
    description,
    setDescription,
    isModern,
    setIsModern,
    isColorful,
    setIsColorful,
    is3D,
    setIs3D,
    logoType,
    setLogoType,
  } = useContext(FormContext);

  const handleClear = () => {
    setDescription("");
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
    // Automatically adjust the textarea height based on its content
    e.target.style.height = "auto";
    e.target.style.height = `${e.target.scrollHeight}px`;
  };

  const handleCheckboxChange = (logoType) => {
    setLogoType(logoType);
  };

  return (
    <div className="user-input-form">
      <form>
        <div className="form-group">
          <label className="section-header">Project Description</label>
          <textarea
            className="project-description"
            value={description}
            onChange={handleDescriptionChange}
            maxLength={600}
          />
          <div className="character-counter">{description.length}/600</div>
        </div>

        <div className="divider"></div>
        <div className="section-header">Theming</div>

        <div className="theme-group">
          <div className="switch-group">
            {/* Switch for Modern/Classic */}
            <div className="form-switch">
              <div className="switch-label">Classic</div>
              <Switch
                onChange={() => setIsModern(!isModern)}
                checked={isModern}
                onColor="#8A29F3"
                offColor="#666"
              />
              <div className="switch-label">Modern</div>
            </div>

            {/* Switch for Black and White/Colorful */}
            <div className="form-switch">
              <div className="switch-label">Black and White</div>
              <Switch
                onChange={() => setIsColorful(!isColorful)}
                checked={isColorful}
                onColor="#8A29F3"
                offColor="#666"
              />
              <div className="switch-label">Colorful</div>
            </div>

            {/* Switch for Flat/3D */}
            <div className="form-switch">
              <div className="switch-label">Flat</div>
              <Switch
                onChange={() => setIs3D(!is3D)}
                checked={is3D}
                onColor="#8A29F3"
                offColor="#666"
              />
              <div className="switch-label">3D</div>
            </div>

            <label>Choose a Logo Type</label>
            <div className="checkbox-container">
              <CheckboxWithImage
                image="images/LettermarkLogo.png"
                label="Lettermark"
                isChecked={logoType === "Lettermark"}
                handleCheckboxChange={() => handleCheckboxChange("Lettermark")}
              />
              <CheckboxWithImage
                image="images/MascotLogo.png"
                label="Mascot"
                isChecked={logoType === "Mascot"}
                handleCheckboxChange={() => handleCheckboxChange("Mascot")}
              />
              <CheckboxWithImage
                image="images/EmblemLogo.png"
                label="Emblem"
                isChecked={logoType === "Emblem"}
                handleCheckboxChange={() => handleCheckboxChange("Emblem")}
              />
            </div>
          </div>

          <div
            className={`color-picker-group ${
              isColorful ? "" : "disabled-color-picker"
            }`}
          >
            {/*Color Picker Item */}
            <ColorPicker />
          </div>
        </div>

        <div className="divider"></div>

        <div className="button-group">
          <button type="button" onClick={handleClear} className="clear-button">
            Clear
          </button>
          <button
            className="submit-button"
            type="button"
            onClick={props.onSubmitFunction}
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}

export default UserInputForm;
