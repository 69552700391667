import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "./styles/scrollbar.css";
import "./styles/theme.css";

import React, { useState, createContext } from "react";
import { Routes, Route } from "react-router-dom";

import ResultsPage from "./my-components/ResultsPage";
import HomePage from "./my-components/HomePage";
import MyNavbar from "./my-components/Navbar";
import { Header } from "./my-components/login/Header";
import { Footer } from "./my-components/login/Footer";
import { SignInFooter } from "./my-components/login/SignInFooter";
import { Authenticator } from "@aws-amplify/ui-react";

export const AppContext = createContext();

const components = {
  Header,
  SignIn: {
    Footer: SignInFooter,
  },
  ConfirmSignUp: {},
  Footer,
};

function App() {
  const [userEmail, setUserEmail] = useState();
  const [userCredits, setUserCredits] = useState();

  return (
    <Authenticator components={components}>
      <AppContext.Provider
        value={{
          userEmail,
          setUserEmail,
          userCredits,
          setUserCredits,
        }}
      >
        <div>
          <MyNavbar />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/results" element={<ResultsPage />} />
          </Routes>
        </div>
      </AppContext.Provider>
    </Authenticator>
  );
}

export default App;
